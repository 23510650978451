<template>
    <div v-if="promotions">
        <header-comp v-if="useVersion == 1" />
        <header-v2-comp v-else-if="useVersion == 2" />
        
        <div class="main-container">
            <div class="container">
                <!-- Initiate Routing Child components -->
                <router-view />
            </div>
        </div>

        <footer-comp v-if="useVersion == 1" :topfooter="true" />
        <footer-v2-comp v-else-if="useVersion == 2" />
    </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'

import HeaderComp from '../../containers/Header'
import HeaderV2Comp from '../../containers/HeaderV2'
import FooterComp from '../../containers/Footer'
import FooterV2Comp from '../../containers/FooterV2'

export default {
    components: {
        HeaderComp,
        HeaderV2Comp,
        FooterComp,
        FooterV2Comp
    },
    data(){
        return {
            useVersion: 1
        }
    },
    $route(to, from){
        if(to.path != from.path) this.checkPath()
    },
    computed: {
        ...mapGetters(['promotions']),
    },
    methods: {
        ...mapMutations(['resyncPromo']),
        checkPath(){
            //use version 2
            this.useVersion = 2
            // if(this.$route.path.includes('/customer/dashboard-booking')) {
            //     this.useVersion = 2
            //     // if(this.$route.path.includes('/home-nursing/booking')) this.useBGColor1 = true
            //     // else this.useBGColor1 = false
            // }
            // else {
            //     this.useVersion = 1
            // }
        },
    },
    created(){
        this.resyncPromo() //sync online store promotions
    },
    mounted(){
        this.checkPath()
    }
}
</script>

<style>
.main-container {
    padding: 20px 0px;
}
.gray-color {
    background-color: #f6f6f6;
}
</style>